interface RemoteFeatureFlagConfig<TKey extends string, TValues, TDefaultValue> {
  key: TKey;
  queryParam: string;
  supportedValues: TValues;
  defaultValue: TDefaultValue;
}

export interface RemoteFeatureFlagOptions {
  /**
   * Skip evaluating the remote feature flag, if true.
   * This is useful to ensure that we only read a flag from Amplitude (which Amplitude tracks) if we're going to use it.
   *
   * Defaults to false.
   */
  skip?: boolean;
}

interface RemoteFeatureFlagParams<
  TKey extends string,
  TValues extends string[] = ['on'],
  TDefaultValue extends TValues[number] | undefined = undefined
> {
  /**
   * The name of the feature flag. This must match (case insensitively) a feature flag or experiment defined in the remote config service.
   */
  key: TKey;

  /**
   * The possible defined values of the feature flag. This should match (case insensitively) the variants for the flag defined in the remote
   * config service. Any value for the feature flag returned from the remote config service which is not in this list will be ignored.
   *
   * Defaults to `['on']`, which is used for an on/off feature flag.
   */
  supportedValues?: readonly [...TValues];

  /**
   * The default value for the feature flag. This will be returned if the value of the feature flag is undefined, which can occur in the following cases:
   *
   *   - The feature flag value is being loaded from the remote config service.
   *   - The feature flag is not defined in the remote config service.
   *   - The feature flag is defined in the remote config service, but the user is not assigned to the feature flag.
   *   - The feature flag is defined in the remote config service, but the user is assigned to a variant which is not in the `supportedValues` list.
   *
   * Defaults to `undefined`.
   */
  defaultValue?: TDefaultValue;
}

export function createRemoteFeatureFlag<Key extends string>(key: Key): RemoteFeatureFlagConfig<Key, ['on'], undefined>;

/**
 * For more details on feature flagging and experimentation, see: https://withjoy.atlassian.net/wiki/spaces/KNOW/pages/2195718182
 */
export function createRemoteFeatureFlag<Key extends string, Values extends string[], DefaultValue extends Values[number]>({
  key,
  supportedValues,
  defaultValue,
}: RemoteFeatureFlagParams<Key, Values, DefaultValue>): RemoteFeatureFlagConfig<Key, Values, DefaultValue>;
export function createRemoteFeatureFlag(
  params: string | RemoteFeatureFlagParams<string, string[], string>
): RemoteFeatureFlagConfig<string, string[], string | undefined> {
  const { key, supportedValues = ['on'], defaultValue }: RemoteFeatureFlagParams<string, string[], string> =
    typeof params === 'string' ? { key: params } : params;

  return {
    key,
    queryParam: `feature.${key}`.toLowerCase(),
    supportedValues: supportedValues.map(val => val.toLowerCase()),
    defaultValue,
  };
}
