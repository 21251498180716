export const headerData = {
  universalNav: {
    joyWedding: 'Joy Wedding',
    joyBaby: 'Joy Baby',
    new: 'New',
    findAnEvent: 'Find an Event',
  },
  header: {
    login: 'Log in',
    getStarted: 'Get Started',
    getStartedV2: 'Start Your Free Registry',
    backToAll: 'Back to All',
    findAnEvent: 'Find an Event',
  },
  footer: {
    shipping: 'Shipping',
    returns: 'Returns',
    newsroom: 'Newsroom',
    careers: 'Careers',
    aboutJoy: 'About Joy',
    contactUs: 'Contact Us',
    login: 'Log in',
    getStarted: 'Get Started',
    findAnEvent: 'Find an Event',
    copyright: 'All rights reserved.',
    terms: 'Terms',
    privacy: 'Privacy',
    californiaPrivacyNotice: 'California Privacy Notice',
    doNotShareInformation: 'Do not share my personal information.',
    tradeMarkContent: ['Celebrate life', 'Plan smarter', 'Save the date', 'Announce it', 'Build your registry'],
    withJoyTM: 'With Joy™',
  },
  hotelRoomBlockValueProps: [
    {
      title: 'Hotel Room Blocks',
    },
    {
      title: 'Exclusive Room Rates',
    },
    {
      title: 'Up To 30% Off',
    },
    {
      title: 'No Financial Commitment',
    },
    {
      title: 'Personal Travel Concierge',
    },
  ],
  stationeryValueProps: {
    titleMobile: 'Up to 40% off Save the Dates & Invites',
    titleDesktop: 'Up to 40% off all Save the Dates and Invitations',
    link: '/cards/wedding/save-the-date',
    cta: 'Explore Designs',
  },
} as const;
