import React from 'react';
import { Flex } from '@components/Flex';
import { getRootUrl } from '@utils/urlHelpers.util';
import { headerData } from '@data-ts/shared/header';
import { experiments } from '@utils/experiments/experiments';
import { CatalogStorefront } from '@utils/storefront/storefront.types';
import { useShopStorefrontContext } from '@utils/storefront/ShopStorefrontProvider';
import { StyledBannerContainer, DesktopWrapper, StyledNavButton, StyledFlex, StyledText } from './UniversalNav.styles';

const universalNavData = headerData.universalNav;

interface UniversalNavProps {
  variant?: 'blue' | 'default';
  page?: MarcomPageName;
}

export const UniversalNav: React.FC<UniversalNavProps> = ({ page, variant = 'default' }) => {
  const { currentStorefront } = useShopStorefrontContext();
  return (
    <>
      <StyledBannerContainer paddingX={[0, 6]} variant={variant}>
        <Flex>
          <StyledNavButton
            active={currentStorefront === CatalogStorefront.wedding && page !== 'find'}
            id="universalNavWeddingBtn"
            variant="defaultPrimary"
            forwardedAs={'a'}
            href={getRootUrl('/')}
          >
            {universalNavData.joyWedding}
          </StyledNavButton>
          <StyledNavButton
            active={currentStorefront === CatalogStorefront.baby}
            id="universalNavBabyBtn"
            variant="defaultPrimary"
            forwardedAs={'a'}
            href={getRootUrl('/baby-registry/')}
          >
            <StyledFlex alignItems="center">
              <Flex as="span">{universalNavData.joyBaby}</Flex>
              <StyledText variant="dek20" color="mono1" tagName="span">
                {universalNavData.new}
              </StyledText>
            </StyledFlex>
          </StyledNavButton>
        </Flex>
        <DesktopWrapper>
          <StyledNavButton
            active={page === 'find'}
            id="universalNavFindAnEventBtn"
            variant="defaultPrimary"
            forwardedAs={'a'}
            href={getRootUrl('/find/')}
          >
            {universalNavData.findAnEvent}
          </StyledNavButton>
        </DesktopWrapper>
      </StyledBannerContainer>
    </>
  );
};
