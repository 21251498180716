import { getLocalStorage } from '@utils/storage';
import { RemoteFeatureFlagUserInfo } from './remoteFeatureFlags.types';
import { SEGMENT_ANONYMOUS_ID_KEY } from './remoteFeatureFlags.constants';

const localStorage = getLocalStorage();

export const getAnonymousId = () => {
  const anonymousId = (localStorage.getItem(SEGMENT_ANONYMOUS_ID_KEY) || '').replaceAll('"', '');
  return anonymousId;
};

export const getRemoteFeatureFlagUser = (): RemoteFeatureFlagUserInfo => {
  const ajsUserTraits = localStorage.getItem('ajs_user_traits') || '';
  let forceHotelBlockEnabled = false;
  let lastFirebaseEventId = '';
  if (ajsUserTraits) {
    try {
      const ajsUserTraitsObject = JSON.parse(ajsUserTraits);
      if (ajsUserTraitsObject?.forceHotelBlockEnabled) {
        forceHotelBlockEnabled = ajsUserTraitsObject.forceHotelBlockEnabled === true;
      }
      lastFirebaseEventId = ajsUserTraitsObject.lastFirebaseEventId || '';
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }
  return {
    userProperties: {
      forceHotelBlockEnabled,
      lastFirebaseEventId,
    },
  };
};
