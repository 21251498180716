import { useMemo } from 'react';
import { RemoteFeatureFlagOptions } from './remoteFeatureFlags.create';
import { FeatureFlags, features } from './remoteFeatureFlags.constants';
import { RemoteFeatureFlag, RemoteFeatureFlagKeys } from './remoteFeatureFlags.types';
import { useRemoteFeatureFlagValue } from './useRemoteFeatureFlagValue';
import { withWindow } from '@utils/withWindow';
import { isSupportedFeatureValue } from './remoteFeatureFlags.utils';

export function useRemoteFeatureFlag<
  TFeatureName extends RemoteFeatureFlagKeys,
  TSupportedValues extends FeatureFlags[TFeatureName]['supportedValues'][number]
>(featureName: TFeatureName, options?: RemoteFeatureFlagOptions): RemoteFeatureFlag<TFeatureName> {
  const { loading, getFeatureFlagValue } = useRemoteFeatureFlagValue();

  const remoteFeatureFlag = useMemo((): RemoteFeatureFlag<TFeatureName> => {
    const { defaultValue, supportedValues } = features[featureName];
    if (options?.skip) {
      return {
        loading: false,
        value: defaultValue,
      };
    }

    const searchValue = withWindow(global => {
      const searchParams = new URLSearchParams(global.location.search?.toLowerCase());
      const searchValue = featureName ? searchParams.get(features[featureName].queryParam) : undefined;
      if (searchValue) {
        return searchValue as RemoteFeatureFlag<TFeatureName>['value'];
      }
      return null;
    }, null);

    if (searchValue) {
      const value = isSupportedFeatureValue(searchValue, supportedValues)
        ? (searchValue as RemoteFeatureFlag<TFeatureName>['value'])
        : defaultValue;
      return {
        loading: false,
        value,
      };
    }

    if (loading || !featureName) {
      return {
        loading: true,
        value: defaultValue,
      };
    }
    const value = getFeatureFlagValue(featureName).value as RemoteFeatureFlag<TFeatureName>['value'];
    return {
      loading: false,
      value,
    };
  }, [options, loading, featureName, getFeatureFlagValue]);

  return remoteFeatureFlag;
}
