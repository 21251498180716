import { useCallback, useEffect, useRef, useState } from 'react';
import { RemoteFeatureFlag, RemoteFeatureFlagKeys, RemoteFeatureFlagUserInfo } from './remoteFeatureFlags.types';
import { FeatureFlags, features } from './remoteFeatureFlags.constants';
import { useRemoteFeatureFlagService } from './useRemoteFeatureFlagService';
import { getAnonymousId, getRemoteFeatureFlagUser } from './remoteFeatureFlags.user';
import { isSupportedFeatureValue } from './remoteFeatureFlags.utils';

enum RemoteFeatureFlagProviderState {
  Loading,
  Ready,
}

export const useRemoteFeatureFlagValue = () => {
  const [state, setState] = useState<RemoteFeatureFlagProviderState>(RemoteFeatureFlagProviderState.Loading);
  const lastFetchedUserProfile = useRef<RemoteFeatureFlagUserInfo>({});
  const [anonymousId, setAnonymousId] = useState<string | undefined>(undefined);
  const prevStateRef = useRef<RemoteFeatureFlagProviderState>(RemoteFeatureFlagProviderState.Loading);

  const remoteFeatureFlagService = useRemoteFeatureFlagService();

  // anonymousId - Is unique id for user for user bucketing in amplitude instead of userId in marcom
  const fetchAnonymousId = useCallback(() => {
    const id = getAnonymousId();
    if (id) {
      setAnonymousId(id);
    } else {
      setTimeout(fetchAnonymousId);
    }
  }, []);

  useEffect(() => {
    if (!anonymousId) {
      fetchAnonymousId();
    }
  }, [anonymousId, fetchAnonymousId]);

  useEffect(() => {
    if (!anonymousId) {
      return;
    }

    const fetchRemoteFeatureFlags = async () => {
      const remoteFeatureFlagUser = getRemoteFeatureFlagUser();
      const userInfoToFetch: RemoteFeatureFlagUserInfo = {
        userId: anonymousId,
        deviceId: anonymousId,
        eventFirebaseId: remoteFeatureFlagUser.eventFirebaseId,
        userProperties: remoteFeatureFlagUser.userProperties,
      };
      lastFetchedUserProfile.current = userInfoToFetch;

      if (prevStateRef.current !== RemoteFeatureFlagProviderState.Loading) {
        setState(RemoteFeatureFlagProviderState.Loading);
        prevStateRef.current = RemoteFeatureFlagProviderState.Loading;
      }

      try {
        await remoteFeatureFlagService.fetchUser(userInfoToFetch);
        // eslint-disable-next-line no-empty
      } catch (err) {}

      if (prevStateRef.current === RemoteFeatureFlagProviderState.Loading) {
        setState(RemoteFeatureFlagProviderState.Ready);
        prevStateRef.current = RemoteFeatureFlagProviderState.Ready;
      }
    };

    fetchRemoteFeatureFlags();
  }, [anonymousId, remoteFeatureFlagService]);

  const getFeatureFlagValue = useCallback(
    <TFeatureName extends RemoteFeatureFlagKeys, TSupportedValues extends FeatureFlags[TFeatureName]['supportedValues'][number]>(
      featureName: RemoteFeatureFlagKeys
    ): RemoteFeatureFlag<TFeatureName> => {
      const { defaultValue, supportedValues, key: featureKey } = features[featureName];
      const { value } = remoteFeatureFlagService.getFeatureValue(featureKey);

      if (value && isSupportedFeatureValue(value, supportedValues)) {
        remoteFeatureFlagService.trackExposure(featureKey);
        return { loading: false, value };
      }

      return { loading: false, value: defaultValue };
    },
    [remoteFeatureFlagService]
  );

  return { loading: state === RemoteFeatureFlagProviderState.Loading, getFeatureFlagValue };
};
