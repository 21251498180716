import { useMemo } from 'react';
import { RemoteFeatureFlagService } from './remoteFeatureFlags.types';
import { getFeatureValue, loadExperimentUser, trackExposure } from './remoteFeatureFlags.utils';

export function useRemoteFeatureFlagService(): RemoteFeatureFlagService {
  const remoteFeatureFlagService = useMemo<RemoteFeatureFlagService>(() => {
    return {
      fetchUser: async ({ userId, deviceId, eventFirebaseId, userProperties }) => {
        const experimentUser = {
          user_id: userId,
          device_id: deviceId,
          groups: {
            JoyEvent: eventFirebaseId ? [eventFirebaseId] : [],
          },
          user_properties: userProperties,
        };
        await loadExperimentUser(experimentUser);
      },
      getFeatureValue: (key: string) => {
        return getFeatureValue(key);
      },
      trackExposure: (key: string) => {
        trackExposure(key);
      },
    };
  }, []);

  return remoteFeatureFlagService;
}
