import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import shortid from 'shortid';
import { segment } from 'static/js/joy';
import { Integrations } from 'static/js/types';
import { withWindow } from '@utils/withWindow';

export interface ExperimentDetails {
  /**
   * Use this `Component` to wrap a Control or Variant for an experiment.
   */
  readonly Component: StyledComponent<'div', DefaultTheme>;

  /**
   * CSS variable which will be set to `'none'` when the control/variant is disabled. Use this if you're unable to use the
   * `Component` element to enable the control/variant.
   *
   * Example usage: `display: var(experimentName.Control.cssVariable, 'flex')`
   */
  readonly cssVariable: string;
}

export type ABExperimentTraffic = [number, number];

export type ABCExperimentTraffic = [number, number, number];

export interface ABExperiment {
  /**
   * Name of the experiment.
   */
  readonly name: ABExperimentName;

  /**
   * User Traffic A, B experiment in percentage respectively.
   */
  readonly traffic: ABExperimentTraffic;

  /**
   * Control for the experiment. This should be used as the baseline or existing functionality to measure against.
   */
  readonly Control: ExperimentDetails;

  /**
   * Variant for the experiment. This should be used to test new functionality or UI.
   */
  readonly Variant: ExperimentDetails;
}

export interface ABCExperiment extends Omit<ABExperiment, 'traffic'> {
  /**
   * User Traffic A, B, C experiment in percentage respectively.
   */
  readonly traffic: ABCExperimentTraffic;

  /**
   * VariantV2 for the experiment. This should be used to test new functionality or UI parallel with Variant.
   */
  readonly VariantV2: ExperimentDetails;
}

function createSingleExperiment(cssVariable: string): ExperimentDetails {
  return {
    cssVariable: cssVariable,

    // Use `!important` on display prop to ensure it has the highest specificity and won't get overridden
    // by a parent style for this Component.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: styled.div.withConfig({ componentId: `Experiments__Component__${shortid.generate()}`, key: shortid.generate() } as any)({
      display: `var(${cssVariable}, block) !important`,
    }),
  };
}

function createABExperiment(experimentName: ABExperimentName, traffic?: ABExperimentTraffic): ABExperiment {
  const experiments: ABExperiment = {
    name: experimentName,
    traffic: traffic ?? [50, 50],
    Control: createSingleExperiment(`--ab-experiment_${experimentName}-control`),
    Variant: createSingleExperiment(`--ab-experiment_${experimentName}-variant`),
  };
  return experiments;
}

function createABCExperiment(experimentName: ABExperimentName, traffic?: ABCExperimentTraffic): ABCExperiment {
  const experiments: ABCExperiment = {
    name: experimentName,
    traffic: traffic ?? [33, 33, 33],
    Control: createSingleExperiment(`--ab-experiment_${experimentName}-control`),
    Variant: createSingleExperiment(`--ab-experiment_${experimentName}-variant`),
    VariantV2: createSingleExperiment(`--ab-experiment_${experimentName}-variant-2`),
  };
  return experiments;
}

export enum ABExperimentName {
  SampleABExperiment = 'sampleABExperiment',
  SampleABCExperiment = 'sampleABCExperiment',
}

export enum ABExperimenKey {
  SampleABExperiment = 'SampleABExperiment',
  SampleABCExperiment = 'SampleABCExperiment',
}

export const experiments = {
  [ABExperimenKey.SampleABExperiment]: createABExperiment(ABExperimentName.SampleABExperiment),
  [ABExperimenKey.SampleABCExperiment]: createABCExperiment(ABExperimentName.SampleABCExperiment),
} as const;

enum ABExperimentVersion {
  Control,
  Experiment,
  ExperimentV2,
}

// Log experiments to telemetry
let experimentsToLogToTelemetry: Record<string, string> = {};

withWindow(global => {
  experimentsToLogToTelemetry = Object.values(experiments).reduce(
    (acc: Record<string, string>, { name }) => ({
      ...acc,
      [`marcomExperiment_${name}`]: ABExperimentVersion[global.experiments[name]],
    }),
    {}
  );
});

segment.identify(experimentsToLogToTelemetry, {
  integrations: { [Integrations.Amplitude]: true },
});
