import { createRemoteFeatureFlag } from './remoteFeatureFlags.create';
import { FeatureFlagsContextType, FeatureFlagsState } from './remoteFeatureFlags.types';

export const FEATURE_FLAG_VALUES = {
  marcomTestExperiment: createRemoteFeatureFlag({
    key: 'marcomTestExperiment',
    supportedValues: ['control', 'treatment'],
    defaultValue: 'control',
  }),
  marcomPrintBannerExperiment: createRemoteFeatureFlag({
    key: 'marcomPrintBannerExperiment',
    supportedValues: ['control', 'treatment'],
  }),
};

export type FeatureFlags = typeof FEATURE_FLAG_VALUES;

const featureState = Object.entries(FEATURE_FLAG_VALUES).reduce((acc, [key]) => {
  acc[key as keyof FeatureFlagsState] = undefined;
  return acc;
}, {} as FeatureFlagsState);

export const features = Object.entries(FEATURE_FLAG_VALUES).reduce((acc, [feature, featureValue]) => {
  acc[feature as keyof FeatureFlagsState] = {
    ...featureValue,
    // @ts-expect-error something is off with the types
    value: featureState[feature as keyof FeatureFlagsState],
  };
  return acc;
}, {} as FeatureFlagsContextType['features']);

export const SEGMENT_ANONYMOUS_ID_KEY = 'ajs_anonymous_id';
