import { Flex } from '@components/Flex';
import { styled } from '@styledComponents';
import { Button } from '@components/Button';
import { TOP_BANNER_HEIGHT } from 'src/layout/Layout.constants';
import { Label2 } from '@components/typography/interVariants';
import { themeColor, themeSpace, themeMediaQuery } from '@utils/styledTheme.util';
import { Box } from '@components/Box';

export const StyledNavPropsButton = styled(Button)`
  ${Label2};
  height: ${TOP_BANNER_HEIGHT}px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-radius: 0px;
  padding: 0 ${themeSpace(5)};
  background: #4951ef;
  backdrop-filter: blur(8px) saturate(180%);
  &:hover {
    background: #4951ef;
    color: ${themeColor('white')};
  }
  &:active {
    background: #4951ef;
    color: ${themeColor('white')};
  }

  z-index: 4;
  position: relative;
  width: 100%;
  ${themeMediaQuery('sm')} {
    top: 0;
    left: 50%;
    width: fit-content;
    position: absolute;
    transform: translate(-50%, 0);

    backdrop-filter: none;
    background: transparent;
    &:hover {
      background: transparent;
    }
    &:active {
      background: transparent;
    }
  }
`;

export const ValuePropsTextMobile = styled(Box)`
  ${themeMediaQuery('sm')} {
    display: none;
  }
`;

export const ValuePropsTextDesktop = styled(Box)`
  ${themeMediaQuery('below_sm')} {
    display: none;
  }
`;

export const ValuePropsContainer = styled(Flex)`
  position: absolute;
  height: ${TOP_BANNER_HEIGHT}px;
  left: 50%;
  transform: translate(-50%, 0);
  gap: ${themeSpace(4)};

  ${themeMediaQuery('sm')} {
    left: calc(50% + 3rem);
  }

  ${themeMediaQuery('md')} {
    left: 50%;
  }
`;
