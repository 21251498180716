import { ExperimentUser } from '@amplitude/experiment-js-client';
import { RemoteFeatureFlagKeys } from './remoteFeatureFlags.types';
import { FeatureFlags } from './remoteFeatureFlags.constants';

export const loadExperimentUser = async (experimentUser: ExperimentUser) => {
  const global = window;
  if (!global.amplitudeExperimentClient) {
    return;
  }

  try {
    let loadPromise;
    if (global.amplitudeExperimentClient.isFirstSdkLoad) {
      global.amplitudeExperimentClient.isFirstSdkLoad = false;
      loadPromise = global.amplitudeExperimentClient.start.call(window.amplitudeExperimentClient, experimentUser);
    } else {
      loadPromise = global.amplitudeExperimentClient.fetch.call(window.amplitudeExperimentClient, experimentUser);
    }
    await loadPromise;
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

export const getFeatureValue = (key: string) => {
  const global = window;
  if (!global.amplitudeExperimentClient) {
    return { value: undefined };
  }
  return global.amplitudeExperimentClient.variant(key.toLowerCase());
};

export const trackExposure = (key: string) => {
  const global = window;
  if (global.amplitudeExperimentClient) {
    global.amplitudeExperimentClient.exposure(key.toLowerCase());
  }
};

export const isSupportedFeatureValue = <TFeatureName extends RemoteFeatureFlagKeys>(
  value: string,
  supportedValues: FeatureFlags[TFeatureName]['supportedValues']
): value is FeatureFlags[TFeatureName]['supportedValues'][number] => {
  return Array.isArray(supportedValues) && (supportedValues as string[]).includes(value.toLowerCase());
};
