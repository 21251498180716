import React from 'react';
import { StyledNavPropsButton, ValuePropsContainer, ValuePropsTextDesktop, ValuePropsTextMobile } from './ValuePropsStationery.styles';
import { Box } from '@components/Box';
import { headerData } from '@data-ts/shared/header';

const { stationeryValueProps } = headerData;

interface ValuePropsStationeryProps {}

export const ValuePropsStationery: React.FC<ValuePropsStationeryProps> = () => {
  return (
    <StyledNavPropsButton
      variant="defaultPrimary"
      id="value-props-stationery"
      forwardedAs="a"
      href={stationeryValueProps.link}
      target="_blank"
    >
      <ValuePropsContainer alignItems="center">
        <ValuePropsTextDesktop as="p">{stationeryValueProps.titleDesktop}</ValuePropsTextDesktop>
        <ValuePropsTextMobile as="p">{stationeryValueProps.titleMobile}</ValuePropsTextMobile>
        <Box as="p" style={{ textDecoration: 'underline', textUnderlineOffset: '3px' }}>
          {stationeryValueProps.cta}
        </Box>
      </ValuePropsContainer>
    </StyledNavPropsButton>
  );
};
