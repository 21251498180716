import { withWindow } from '@utils/withWindow';

export type Environment = 'development' | 'production';

function parseEnvironment(str: string): Environment {
  switch (str?.toLowerCase()) {
    case 'production':
      return 'production';
    default:
      return 'development';
  }
}

const searchStr = withWindow(window => window.location.search, '');
const searchParams = new URLSearchParams(searchStr.toLowerCase());

const currentEnvironment = parseEnvironment(searchParams.get('env') ?? (process.env.GATSBY_ENV as string));

export type ValuePerEnvironment<T> = {
  [K in Environment]: T;
};

export type ValuesPerEnvironment<TConfig> = {
  [K in keyof TConfig]: TConfig[K] | ValuePerEnvironment<TConfig[K]>;
};

function hasValuesPerEnvironment<TValue>(value: unknown): value is ValuePerEnvironment<TValue> {
  if (typeof value === 'object' || value !== null) {
    const maybeValuePerEnvironment = value as ValuePerEnvironment<unknown>;
    return (maybeValuePerEnvironment.development && maybeValuePerEnvironment.production) as boolean;
  }

  return false;
}

function extractConfigValueBasedOnEnvironment<TConfig, TKey extends keyof TConfig>(
  value: TConfig[TKey] | ValuePerEnvironment<TConfig[TKey]>
): TConfig[TKey] {
  if (hasValuesPerEnvironment(value)) {
    return value[currentEnvironment];
  }

  return value;
}

export function extractConfigValuesBasedOnEnvironment<TConfig>(eventTypeConfig: ValuesPerEnvironment<TConfig>): TConfig {
  const newConfigEntries = Object.keys(eventTypeConfig).map(key => {
    return [key, extractConfigValueBasedOnEnvironment<TConfig, keyof TConfig>(eventTypeConfig[key as keyof TConfig])];
  });

  return Object.fromEntries(newConfigEntries);
}
